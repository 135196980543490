import React from "react";
import styled from "styled-components";
import ProductLink from "./ProductLink";
import fbicon from "../assets/images/facebook-2@2x.jpg";
import instaicon from "../assets/images/instagram-2@2x.jpg";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Berner from "../assets/svg/berner.inline.svg";
import { Box, Flex } from "@chakra-ui/react";
import { translate } from "../utils/get-locale";

const FooterWrapper = styled.footer`
	position: relative;
	height: 108px;
	width: 100%;
	@media (min-width: 62em) {
		position: absolute;
		bottom: 0;
	}

	@media (min-width: 80em) {
		position: absolute;
		bottom: 0;
	}
`;

const Balls = styled.div`
	height: 108px;
	position: absolute;
	right: 0;
	bottom: 0;
`;

const BallSmall = styled.div`
	position: absolute;
	right: 30px;
	bottom: 64px;
	--size: 41px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: #ff8f1c;
`;

const BallMedium = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	--size: 66px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: #888d30;
`;

const BallLarge = styled.div`
	position: absolute;
	right: 64px;
	bottom: 0;
	--size: 108px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: #fcafc0;
`;

const TextLink = styled.a`
	font-family: "Poppins";
	font-size: 18px;
	font-weight: 900;
	font-style: normal;
	line-height: 1;
	color: #1a1818;
	align-self: start;
`;

const SomeIcon = styled.a`
	display: inline-block;
	width: 17px;
	height: 17px;
	background-image: url(${(props) => props.bg});
	background-size: 17px;
	background-repeat: no-repeat;
	margin: 0 10px;
`;
const Footer = ({ location }) => {
	const breakpoints = useBreakpoint();
	return (
		<>
		{location.pathname.includes("histor") && breakpoints.sm ? 
		null :
		<FooterWrapper>
			{location.pathname.length > 4 ? (
				<Box
					pos="absolute"
					left="23px"
					bottom="10px"
					display={{ base: "none", md: "inherit"}}
				>
					<ProductLink lowerCase={true} />
				</Box>
			) : null}
			<Balls>
				<BallSmall />
				<BallMedium />
				<BallLarge />
			</Balls>

			<Flex
				pos="absolute"
				direction={{ base: "column", md: "row"}}
				alignItems={{ base: "flex-start",  md: "center"}}
				right={{base: "auto",  md: 210}}
				left={{base: "16px",  md: "auto"}}
				bottom={{base: "16px",  md: "6px"}}
				height={{base: "auto",  md: "26px"}}
			>
				<Box as="span" display={{ base: "none", md: "inherit"}}>
					<a href="https://www.berner.fi/" rel="noopener noreferrer" target="_blank"><Berner /></a>
				</Box>
				<Box mx={{base: "-10px", md:30}}>
					<SomeIcon
						bg={instaicon}
						href="https://www.instagram.com/mecfinland/"
						target="_blank"
						rel="noopener noreferrer"
					/>
					<SomeIcon
						bg={fbicon}
						href="https://www.facebook.com/MECfinland/"
						target="_blank"
						rel="noopener noreferrer"
					/>
				</Box>
				<Box as="span" mt={{ base: "6px", md: 0}} mb={{ base: "12px", md: 0}}>
					<TextLink
						href="https://www.berner.fi/privacy-policy/asiakasrekisteri/"
						target="_blank"
						rel="noreferrer"
					>
						{translate("rekisteriseloste")}
					</TextLink>
				</Box>
				<Box as="span" display={{base: "inherit", md: "none"}}>
					<Berner />
				</Box>
			</Flex>
		</FooterWrapper>
		 }
		</>
	);
};

export default Footer;
