import React, { createRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Arrow from "../assets/svg/arrow.inline.svg";
import { randomColor } from "../utils/random-color";
import { getLocaleUrl, translate } from "../utils/get-locale";

const BoldText = styled.p`
	font-family: "Poppins";
	font-weight: 700;
	font-style: normal;
	font-size: 1rem;
	line-height: 1;
	text-align: center;
	color: #1a1818;
	padding-left: 20px;
	padding-bottom: 2px;
	text-transform: ${(props) => props.lowerCase ? "lowercase" : "none"};


	@media (min-width: 62em) {
		font-size: 1.3rem;
	}
`;

export default function ProductLink({lowerCase=false}) {
	const arrow = createRef();

	const mouseOver = () => {
		gsap.fromTo(arrow.current, { x: 0 }, { duration: 0.15, x: 10 });
	};
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, x: 0 });
	};

	return (
		<AniLink
			paintDrip
			hex={randomColor()}
			className="link"
			to={getLocaleUrl() ? `/${getLocaleUrl()}/products` : `/products`}
			onMouseEnter={mouseOver}
			onMouseLeave={mouseLeave}
		>
			<div className="svg-wrapper" ref={arrow}>
				<Arrow />
			</div>
			<BoldText lowerCase={lowerCase}>{translate("mectuotteet")}</BoldText>
		</AniLink>
	);
}
