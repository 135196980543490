import { globalHistory as history } from '@reach/router'

import FI from "../locales/fi/translation.json"
import EN from "../locales/en/translation.json"
import SV from "../locales/sv/translation.json"

const resources = {
    fi: FI,
    en: EN,
    sv: SV
  }

export const getLocale = () => {
    const { location } = history
    //console.log("getLocale",location)
    //const pathArray = window.location.pathname.split('/');
    const pathArray = location.pathname.split('/');

    if(['en','sv'].includes(pathArray[1])) return pathArray[1]

    return 'fi'
}

export const getLocaleUrl = () => {
    const path = getLocale()
    if (path === 'fi') return ''
    return path
}

export const translate = (str) => {
    const t = resources[getLocale()][str]
    return t;
}