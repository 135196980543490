import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './src/ApolloContext';
import Layout from './src/components/layout';

import "./src/styles/global.css"


export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);
export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
