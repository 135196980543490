import React from "react";
import styled from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Footer from "./Footer";

const SiteContentWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	/*min-height: calc(100vh - 108px);
	/*padding-left: 16px;
	padding-right: 16px;*/
	//;
	background: ${(props) =>
		props.showHistoryLine
			? "linear-gradient(90deg, rgba(0,0,0,0) 17px, rgba(255,198,0,1) 19px, rgba(0,0,0,0) 21px)"
			: "#fff"};

	@media (min-width: 48em) {
		background: ${(props) =>
			props.showHistoryLine
				? "linear-gradient(90deg, rgba(0,0,0,0) calc(50% - 2px), rgba(255,198,0,1) calc(50%), rgba(0,0,0,0) calc(50% + 2px))"
				: "#fff"};
	}
`;

const ContentWrapper = styled.section`
	padding-bottom: ${(props) => props.pb}px;
`;
const Layout = ({ children, location }) => {
	const breakpoints = useBreakpoint();
	return (
		<>
			<SiteContentWrapper
				showHistoryLine={location.pathname.includes("histor")}
				className="SiteContentWrapper"
			>
				<ContentWrapper pb={breakpoints.sm ? 0 : 108}>
					{children}
				</ContentWrapper>
				<Footer location={location} />
			</SiteContentWrapper>
		</>
	);
};
export default Layout;
