// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-click-js": () => import("./../../../src/pages/en/click.js" /* webpackChunkName: "component---src-pages-en-click-js" */),
  "component---src-pages-en-collaboration-js": () => import("./../../../src/pages/en/collaboration.js" /* webpackChunkName: "component---src-pages-en-collaboration-js" */),
  "component---src-pages-en-contact-form-js": () => import("./../../../src/pages/en/contact-form.js" /* webpackChunkName: "component---src-pages-en-contact-form-js" */),
  "component---src-pages-en-database-of-encouraging-phrases-js": () => import("./../../../src/pages/en/database-of-encouraging-phrases.js" /* webpackChunkName: "component---src-pages-en-database-of-encouraging-phrases-js" */),
  "component---src-pages-en-history-js": () => import("./../../../src/pages/en/history.js" /* webpackChunkName: "component---src-pages-en-history-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-playdough-prophecy-js": () => import("./../../../src/pages/en/playdough-prophecy.js" /* webpackChunkName: "component---src-pages-en-playdough-prophecy-js" */),
  "component---src-pages-en-products-js": () => import("./../../../src/pages/en/products.js" /* webpackChunkName: "component---src-pages-en-products-js" */),
  "component---src-pages-en-quiet-room-online-js": () => import("./../../../src/pages/en/quiet-room-online.js" /* webpackChunkName: "component---src-pages-en-quiet-room-online-js" */),
  "component---src-pages-en-what-mec-really-means-js": () => import("./../../../src/pages/en/what-mec-really-means.js" /* webpackChunkName: "component---src-pages-en-what-mec-really-means-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internetin-hiljainen-huone-js": () => import("./../../../src/pages/internetin-hiljainen-huone.js" /* webpackChunkName: "component---src-pages-internetin-hiljainen-huone-js" */),
  "component---src-pages-klik-js": () => import("./../../../src/pages/klik.js" /* webpackChunkName: "component---src-pages-klik-js" */),
  "component---src-pages-mecin-historia-js": () => import("./../../../src/pages/mecin-historia.js" /* webpackChunkName: "component---src-pages-mecin-historia-js" */),
  "component---src-pages-mika-ihmeen-mec-js": () => import("./../../../src/pages/mika-ihmeen-mec.js" /* webpackChunkName: "component---src-pages-mika-ihmeen-mec-js" */),
  "component---src-pages-muovailuvahaprofetia-js": () => import("./../../../src/pages/muovailuvahaprofetia.js" /* webpackChunkName: "component---src-pages-muovailuvahaprofetia-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-rohkaisulausepankki-js": () => import("./../../../src/pages/rohkaisulausepankki.js" /* webpackChunkName: "component---src-pages-rohkaisulausepankki-js" */),
  "component---src-pages-saannot-js": () => import("./../../../src/pages/saannot.js" /* webpackChunkName: "component---src-pages-saannot-js" */),
  "component---src-pages-sv-404-js": () => import("./../../../src/pages/sv/404.js" /* webpackChunkName: "component---src-pages-sv-404-js" */),
  "component---src-pages-sv-databas-for-uppmuntrande-fraser-js": () => import("./../../../src/pages/sv/databas-for-uppmuntrande-fraser.js" /* webpackChunkName: "component---src-pages-sv-databas-for-uppmuntrande-fraser-js" */),
  "component---src-pages-sv-historik-js": () => import("./../../../src/pages/sv/historik.js" /* webpackChunkName: "component---src-pages-sv-historik-js" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-sv-klick-js": () => import("./../../../src/pages/sv/klick.js" /* webpackChunkName: "component---src-pages-sv-klick-js" */),
  "component---src-pages-sv-kontakt-js": () => import("./../../../src/pages/sv/kontakt.js" /* webpackChunkName: "component---src-pages-sv-kontakt-js" */),
  "component---src-pages-sv-modelleraprofetia-js": () => import("./../../../src/pages/sv/modelleraprofetia.js" /* webpackChunkName: "component---src-pages-sv-modelleraprofetia-js" */),
  "component---src-pages-sv-products-js": () => import("./../../../src/pages/sv/products.js" /* webpackChunkName: "component---src-pages-sv-products-js" */),
  "component---src-pages-sv-samarbete-js": () => import("./../../../src/pages/sv/samarbete.js" /* webpackChunkName: "component---src-pages-sv-samarbete-js" */),
  "component---src-pages-sv-tysta-rummet-pa-webben-js": () => import("./../../../src/pages/sv/tysta-rummet-pa-webben.js" /* webpackChunkName: "component---src-pages-sv-tysta-rummet-pa-webben-js" */),
  "component---src-pages-sv-vad-mec-egentligen-ar-js": () => import("./../../../src/pages/sv/vad-mec-egentligen-ar.js" /* webpackChunkName: "component---src-pages-sv-vad-mec-egentligen-ar-js" */),
  "component---src-pages-touko-poukon-paskarteluvinkit-js": () => import("./../../../src/pages/touko-poukon-paskarteluvinkit.js" /* webpackChunkName: "component---src-pages-touko-poukon-paskarteluvinkit-js" */),
  "component---src-pages-yhteistyoosio-js": () => import("./../../../src/pages/yhteistyoosio.js" /* webpackChunkName: "component---src-pages-yhteistyoosio-js" */),
  "component---src-pages-yhteys-js": () => import("./../../../src/pages/yhteys.js" /* webpackChunkName: "component---src-pages-yhteys-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

